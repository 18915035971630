import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';

/**CORE COMPONENTS */
import { ExactText, FallBackLoaders } from '@core_common/components';
import { DropdownSettingsController } from '@core_modules/DropdownSettings/controller';
import { SettingsButtons } from '@core_modules/DropdownSettings/components';

function DropdownSettings({ dropdownSettingsVisible, setDropdownSettingsVisible }) {
  const { t } = useTranslation('common');

  const { handleToggleDropdownSettingsModal } = DropdownSettingsController({
    setDropdownSettingsVisible
  });

  return (
    <>
      <Modal
        title={<ExactText text={t('Dropdown Settings')} />}
        visible={dropdownSettingsVisible}
        bodyStyle={{ paddingTop: 0, paddingBottom: 10 }}
        footer={null}
        className="exact-modal"
        style={{ top: 20 }}
        onCancel={handleToggleDropdownSettingsModal}
      >
        <div className="m-2">
          <Suspense fallback={FallBackLoaders.EmptyLoader}>
            <SettingsButtons />
          </Suspense>
        </div>
      </Modal>
    </>
  );
}

export default DropdownSettings;
