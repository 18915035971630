import { types, cast } from 'mobx-state-tree';

const InvestitionenManagementUtilities = types
  .model('InvestitionenManagementUtilities', {
    showAddInvestitionenModal: types.optional(types.boolean, false),
    search: types.optional(types.string, ''),
    props: types.optional(types.array(types.string), [
      'company',
      'date',
      'description',
      'category',
      'account',
      'amount',
      'paid'
    ]),

    isUpdate: types.optional(types.boolean, false),
    updateId: types.optional(types.integer, 0),
    selectedRowsKeys: types.optional(types.maybeNull(types.array(types.integer), [])),
    isDownloading: types.optional(types.boolean, false)
  })
  .views((self) => ({}))
  .actions((self) => ({
    setToggleShowAddOrUpdateInvestitionenModal(isUpdate = false) {
      self.isUpdate = isUpdate ? true : false;
      self.showAddOrUpdateInvestitionenModal = !self.showAddOrUpdateInvestitionenModal;
    },

    setSelectedRowsKeys(selectedRowsKeys) {
      console.log('selected', selectedRowsKeys);
      self.selectedRowsKeys = cast(selectedRowsKeys);
    },

    setIsDownloading(isDownloading) {
      self.isDownloading = isDownloading;
    },

    setSearch(searchValue) {
      self.search = searchValue;
    },
    setUpdateId(id) {
      self.updateId = id;
    },
    getDateFormat(locale) {
      switch (locale) {
        case 'de':
          return 'DD.MM.YYYY';
        default:
          return 'DD-MM-YYYY';
      }
    }
  }));

export default InvestitionenManagementUtilities;
