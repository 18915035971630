import { lazy } from 'react';

const InvestitionenHeader = lazy(() => import('./InvestitionenHeader'));
const InvestitionenTable = lazy(() => import('./InvestitionenTable'));
const AddOrUpdateInvestitionenModal = lazy(() => import('./AddOrUpdateInvestitionenModal'));
const InvestitionenForm = lazy(() => import('./InvestitionenForm'));
const DateForm = lazy(() => import('./DateForm'));

export {
  InvestitionenHeader,
  InvestitionenTable,
  AddOrUpdateInvestitionenModal,
  InvestitionenForm,
  DateForm
};
