import { cast, types } from 'mobx-state-tree';
import Dropdownmodel from './DropdownModel';

const InvestitionenModel = types
  .model('InvestitionenModel', {
    company: types.optional(types.string, ''),
    description: types.optional(types.string, ''),
    id: types.optional(types.integer, 0),
    key: types.optional(types.integer, 0),
    category: types.optional(types.string, ''),
    account: types.optional(types.string, ''),
    amount: types.optional(types.maybeNull(types.string), ''),
    date: types.optional(types.maybeNull(types.string), ''),
    paid: types.optional(types.boolean, false),
    inv_company_name_list: types.optional(types.array(Dropdownmodel), []),
    inv_category_list: types.optional(types.array(Dropdownmodel), []),
    inv_account_list: types.optional(types.array(Dropdownmodel), []),
    inv_paid_list: types.optional(types.array(Dropdownmodel), []),
    currentSelectedDropdown: types.optional(types.string, 'None Selected')
  })
  .views((self) => ({}))
  .actions((self) => ({
    setDropdownList(type, list) {
      self[`${type}_list`] = cast(list);
    },

    setCurrentSelectedDropdown(currentSelected) {
      self.currentSelectedDropdown = currentSelected;
    }
  }));

export default InvestitionenModel;
