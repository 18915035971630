import React from 'react';
import { RiseOutlined } from '@ant-design/icons';
import Routes from '@app_routing/Routes';

const AppMenus = [
  {
    key: '3',
    icon: <RiseOutlined />,
    route: Routes.INVESTITIONEN_ROUTE,
    menuName: 'Investitionen'
  }
];

export default AppMenus;
