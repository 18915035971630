import UserTypesModel from '@core_modules/UserManagement/model/UserTypesModel';
import { types } from 'mobx-state-tree';
import {
  InvestitionenManagementUtilities,
  InvestitionenState
} from '@app_modules/Investitionen/model';

const AppStore = {
  investitionenManagementUtilities: types.optional(InvestitionenManagementUtilities, {}),
  investitionen: types.optional(InvestitionenState, {})
};

export default AppStore;
